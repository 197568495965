import { useEffect } from 'react';
import { vercelEdgeConfigClient } from 'init';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { isNonNullish, isNullish } from 'remeda';
import apiService from 'shared/service/api.service';
import { z } from 'zod';

import { captureMessage } from '@sentry/react';

import { isDemo, isProduction } from './environment';

const FeatureFlagConfigSchema = z.record(z.string(), z.array(z.string()));
type FeatureFlagConfig = z.infer<typeof FeatureFlagConfigSchema>;

const featureFlagsAtom = atom<FeatureFlagConfig | null>(null);

export const useIsFeatureFlagEnabled = (slug: string): boolean => {
  const featureFlags = useAtomValue(featureFlagsAtom);

  if (isNullish(featureFlags)) return false;
  if (isNullish(featureFlags[slug])) return false;

  if (isProduction() || isDemo()) return featureFlags[slug].includes(apiService.tenant);

  return true;
};

export const useLoadFeatureFlags = () => {
  const setFeatureFlags = useSetAtom(featureFlagsAtom);
  const cachedFeatureFlags = window.sessionStorage.getItem('featureFlags');

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const flags = isNonNullish(cachedFeatureFlags)
        ? JSON.parse(cachedFeatureFlags)
        : await vercelEdgeConfigClient.get('featureFlags');

      const validationResult = FeatureFlagConfigSchema.safeParse(flags);

      if (validationResult.success) {
        setFeatureFlags(validationResult.data);
        window.sessionStorage.setItem('featureFlags', JSON.stringify(validationResult.data));
      } else handleError(validationResult.error);
    };

    fetchFeatureFlags();
  }, [setFeatureFlags]);
};

function handleError(error: z.ZodError<Record<string, string[]>>) {
  console.error("Couldn't parse feature flags object from Vercel Edge Config.", error);
  captureMessage(
    "WARNING: Couldn't parse feature flags object from Vercel Edge Config. Zod error attached to sentry event.",
    { extra: { zodError: error } },
  );
}
